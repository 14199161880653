<template>
  <div>
    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{backgroundImage:`url(${require('@/assets/images/banner/banner.png')})`}"
      >
        <b-card-body class="text-center">
          <h2 class="text-primary">
            {{ $t('faq.titles.title') }}
          </h2>
          <b-card-text class="mb-2">
            {{ $t('faq.titles.subtitle') }}
          </b-card-text>

          <!-- form -->
          <b-form class="faq-search-input">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="searchbar"
                v-model="faqSearchQuery"
                :placeholder="$t('faq.titles.search')"
              />
            </b-input-group>
          </b-form>
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ faq search section -->

    <!-- frequently asked questions tabs pills -->
    <section id="faq-tabs">
      <b-tabs
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
      >

        <!-- payment tab -->
        <b-tab
          v-for="(categoryObj, index) in filteredFaq"
          :key="categoryObj.title"
          :active="($route.query.tab && $route.query.tab === categoryObj.slug) || !index"
        >

          <!-- title -->
          <template #title>
            <feather-icon
              :icon="categoryObj.icon"
              size="18"
              class="mr-1"
            />
            <span class="font-weight-bold">{{ $options.filters.transl(categoryObj, 'title') }}</span>
          </template>

          <faq-question-answer :options="categoryObj" />
        </b-tab>
        <!--/ payment tab -->

        <!-- sitting lady image -->
        <template #tabs-end>
          <b-img
            fluid
            :src="require('@/assets/images/illustration/faq-illustrations.svg')"
            class="d-none d-md-block mt-auto"
          />
        </template>
        <!--/ sitting lady image -->
      </b-tabs>
    </section>
    <!--/ frequently asked questions tabs pills -->

    <!-- contact us -->
    <section class="faq-contact">
      <b-row class="mt-5 pt-75 text-center">
        <b-col cols="12">
          <h2>{{ $t('faq.titles.title2') }}</h2>
          <b-card-text class="mb-3">
            {{ $t('faq.titles.subtitle2') }}
          </b-card-text>
        </b-col>
        <b-col sm="6">
          <a
            href="https://t.me/TeLeAds_Support_Bot"
            target="_blank"
          >
            <b-card class="shadow-none py-1 faq-contact-card">
              <b-avatar
                size="42"
                rounded
                variant="light-primary"
                class="mb-2"
              >
                <feather-icon
                  icon="SendIcon"
                  size="18"
                />
              </b-avatar>
              <h4>@TeLeAds_Support_Bot</h4>
              <span class="text-body">{{ $t('faq.titles.phone.title') }}</span>
            </b-card>
          </a>
        </b-col>
        <b-col sm="6">
          <a
            href="mailto:help@teleads.com.ua"
            target="_blank"
          >
            <b-card class="shadow-none py-1 faq-contact-card">
              <b-avatar
                size="42"
                rounded
                variant="light-primary"
                class="mb-2"
              >
                <feather-icon
                  icon="MailIcon"
                  size="18"
                />
              </b-avatar>
              <h4>help@teleads.com.ua</h4>
              <span class="text-body">{{ $t('faq.titles.email.title') }}</span>
            </b-card>
          </a>
        </b-col>
      </b-row>
    </section>
    <!--/ contact us -->
  </div>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import FaqQuestionAnswer from './FaqQuestionAnswer.vue'

export default {
  components: {
    FaqQuestionAnswer,
  },
  mixins: [GlobalMixin],
  metaInfo: {
    title: 'FAQ | TeLeAds — біржа реклами в Телеграм',
    meta: [
      { vmid: 'description', name: 'description', content: 'Швидкі відповіді на питання про роботу біржі та розміщення реклами в каналах ✈️ TeLeAds' },
    ],
  },
  data() {
    return {
      faqSearchQuery: '',
      faqData: null,
    }
  },
  computed: {
    filteredFaq() {
      const filteredData = {}
      if (this.faqData && this.faqData.length && this.faqSearchQuery.length) {
        const queryLowered = this.faqSearchQuery.toLowerCase()
        Object.entries(this.faqData).forEach(entry => {
          const [categoryName, categoryObj] = entry
          // eslint-disable-next-line arrow-body-style
          const filteredQAndAOfCategory = categoryObj.faq.filter(qAndAObj => {
            return this.$options.filters.transl(qAndAObj, 'description').toLowerCase().includes(queryLowered) || this.$options.filters.transl(qAndAObj, 'title').toLowerCase().includes(queryLowered)
          })
          if (filteredQAndAOfCategory.length) {
            filteredData[categoryName] = { ...categoryObj, faq: filteredQAndAOfCategory }
          }
        })
      }
      return this.faqSearchQuery.length ? filteredData : this.faqData
    },
  },
  async beforeCreate() {
    this.$http.get('/api/promo/categories/', {
      params: {
        type: 'faq',
        status: 'enabled',
      },
    })
      .then(response => {
        this.faqData = response.data.data
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('notifications.not_found'),
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
